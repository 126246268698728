<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="14" sm="12" md="12">
            <material-card icon="mdi-receipt" icon-small color="secondary">
              <v-col>
                <v-row
                  style="float: right; margin-right: 10px; margin-top: -50px"
                >
                  <v-chip
                    outlined
                    color="green"
                    @click="csvDownload"
                    style="cursor: pointer"
                    class="mr-5"
                  >
                    <v-icon> mdi-download-circle</v-icon>
                    <span class="black--text font-weight-bold"> CSV </span>
                  </v-chip>
                  <v-chip
                    outlined
                    color="green"
                    @click="pdfDownload"
                    style="cursor: pointer"
                  >
                    <v-icon> mdi-download-circle</v-icon>
                    <span class="black--text font-weight-bold"> PDF </span>
                  </v-chip>
                </v-row>
              </v-col>
              <v-card-title>
                <v-col>
                  <v-row justify="center">
                    <v-btn
                      color="secondary"
                      style="
                        margin-bottom: 20px;
                        margin-top: -10px;
                        margin-right: 10px;
                      "
                      dark
                      @click="getToday"
                    >
                      <v-card-text class="black--text font-weight-bold">
                        Today
                      </v-card-text>
                    </v-btn>
                    <v-btn
                      color="secondary"
                      style="
                        margin-bottom: 20px;
                        margin-top: -10px;
                        margin-right: 10px;
                      "
                      dark
                      @click="getYesterday"
                    >
                      <v-card-text class="black--text font-weight-bold">
                        Yesterday
                      </v-card-text>
                    </v-btn>
                    <v-btn
                      color="secondary"
                      style="
                        margin-bottom: 20px;
                        margin-top: -10px;
                        margin-right: 10px;
                      "
                      dark
                      @click="getBeforeYesterday"
                    >
                      <v-card-text class="black--text font-weight-bold">
                        {{ beforeYesterday }}
                      </v-card-text>
                    </v-btn>
                    <v-btn
                      color="secondary"
                      style="
                        margin-bottom: 20px;
                        margin-top: -10px;
                        margin-right: 10px;
                      "
                      dark
                      @click="getMonthly"
                    >
                      <v-card-text class="black--text font-weight-bold">
                        Monthly Sales
                      </v-card-text>
                    </v-btn>
                    <v-btn
                      color="secondary"
                      style="
                        margin-bottom: 20px;
                        margin-top: -10px;
                        margin-right: 10px;
                      "
                      dark
                      @click="getYearly"
                    >
                      <v-card-text class="black--text font-weight-bold">
                        Yearly Sales
                      </v-card-text>
                    </v-btn>
                  </v-row>

                  <v-row justify="center">
                    <v-col cols="4">
                      <v-text-field
                        v-model="initialFinal"
                        label="From"
                        @click="(setInitial = true), dateModalActive()"
                        outlined
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="endFinal"
                        label="To"
                        @click="(setEnd = true), dateModalActive()"
                        outlined
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-autocomplete
                        v-model="product"
                        :items="products"
                        :loading="isProductLoading"
                        :search-input.sync="searchProduct"
                        hide-no-data
                        label="Search Product..."
                        item-text="productName"
                        item-value="productId"
                        return-object
                        clearable
                        outlined
                      />
                    </v-col>
                  </v-row>

                  <v-row justify="space-between" style="margin-top: -35px">
                    <v-col>
                      <v-btn color="secondary" dark @click="getData">
                        <v-card-text class="black--text font-weight-bold">
                          Get Data
                        </v-card-text>
                      </v-btn>
                    </v-col>
                    <v-col>
                      <div class="total-price" dark>
                        <v-card-text class="white--text font-weight-bold pa-1">
                          Bill Total : {{ currencyFormat(totalPrice) }}
                        </v-card-text>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card-title>

              <v-data-table
                :search="search"
                :headers="headers"
                :items="sales"
                item-key="billNo"
                class="elevation-1"
              >
                <template v-slot:[`item.orderType`]="{ item }">
                  <div>
                    <v-chip
                      label
                      outlined
                      color="primary"
                      style="color: white; width: 100%"
                      v-if="item.orderType === 0"
                    >
                      <v-icon left> mdi-food-takeout-box </v-icon>
                      Pick Up
                    </v-chip>
                    <v-chip
                      label
                      outlined
                      color="primary"
                      style="color: white; width: 100%"
                      v-if="item.orderType === 1"
                    >
                      <v-icon left> mdi-bike-fast </v-icon>
                      Delivery
                    </v-chip>
                    <v-chip
                      label
                      outlined
                      color="primary"
                      style="color: white; width: 100%"
                      v-if="item.orderType === 2"
                    >
                      <v-icon left> mdi-food-fork-drink </v-icon>
                      Dine In
                    </v-chip>
                  </div>
                </template>

                <template v-slot:[`item.quantity`]="{ item }">
                  {{
                    item.unitQty && item.unitQty > 0
                      ? item.quantity / item.unitQty
                      : item.quantity
                  }}
                  {{ item.unitName }}
                </template>

                <template v-slot:[`item.billDate`]="{ item }">
                  {{ item.billDate | localDate }}
                </template>

                <template v-slot:[`item.barcode`]="{ item }">
                  <div style="cursor: pointer" @click="popupBarcode(item)">
                    <qrcode-vue
                      v-if="item.barcode"
                      class="mt-2 mb-n2"
                      :size="30"
                      render-as="svg"
                      :value="item.barcode"
                      level="H"
                    />

                    {{ item.barcode }}
                  </div>
                </template>
              </v-data-table>
            </material-card>
            <!-- <v-row class="mt-5" justify="center">
              <v-col cols="8" sm="8" md="8">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  @click="changePage"
                  circle
                  color="black"
                ></v-pagination>
              </v-col>
            </v-row>  -->
          </v-col></v-row
        >
      </v-container>
    </v-card-text>

    <v-dialog v-model="dateModal" persistent width="290px">
      <div>
        <v-date-picker v-model="datePick" color="secondary">
          <v-spacer></v-spacer>
          <v-btn text color="secondary" @click="closeDateModal"> Cancel </v-btn>
          <v-btn text color="secondary" @click="addDate"> OK </v-btn>
        </v-date-picker>
      </div>
    </v-dialog>
    <!-- <v-dialog v-model="barcodeDialog" persistent width="300px">
      <qrcode-vue
      
        style=""
        :size="30"
        render-as="svg"
        :value="productBarcode"
        level="H"
      />
    </v-dialog> -->

    <v-dialog v-model="barcodeDialog" max-width="400px" origin="top right">
      <v-card>
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="barcodeDialog = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>
        <v-card-title>
          <span class="headline"> {{ productBarcode }} </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <qrcode-vue
              style=""
              :size="300"
              render-as="svg"
              :value="productBarcode"
              level="H"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
var moment = require("moment");

import QrcodeVue from "qrcode.vue";
import Vue from "vue";
Vue.component("qrcode-vue", QrcodeVue);
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  components: { Loading },
  data() {
    return {
      searchProductTimeout: null,
      searchProduct: "",
      isProductLoading: false,
      product: null,
      products: [],
      search: "",
      isLoading: false,
      loadingSize: 50,
      loadingFullPage: true,
      loading: false,

      expanded: [],
      singleExpand: false,

      sales: [],
      trashed: [],
      page: 1,
      pageCount: null,
      start: 0,
      end: 7,
      dataFiltered: [],

      headers: [
        // { text: "Invoice Id", value: "productId", sortable: false },
        // { text: "Product Id", value: "productId" },
        { text: "Barcode", value: "barcode" },
        { text: "Product Name", value: "productName" },

        { text: "Quantity", value: "quantity" },
        { text: "Department", value: "department" },
        { text: "Sub Department", value: "subdepartment" },

        { text: "Invoice Date", value: "billDate" },
        { text: "Real Retail Price", value: "realRetailPrice" },
        { text: "Total Price", value: "retailPrice" },
        { text: "Discount", value: "discount" },
      ],
      paymentView: false,
      billPayment: {
        billPaymentNo: 0,
        billNo: 0,
        payMethod: "card",
        payAmount: 0,
      },

      payMethod: [
        { text: "Card ", value: "card" },
        { text: "Cash ", value: "cash" },
        { text: "Credit ", value: "credit" },
        { text: "Cheque ", value: "cheque" },
        { text: "Voucher ", value: "voucher" },
      ],

      setInitial: false,
      dateModal: false,
      datePick: "",
      editedDate: {
        from: "",
        to: "",
      },
      initialFinal: "",
      endFinal: "",

      setInitial: "false",
      setEnd: "false",
      today: "",
      yesterday: "",
      beforeYesterday: "",
      specificDate: "",
      monthFirst: "",
      barcodeDialog: false,
      productBarcode: "",
      totalPrice: 0,

      itemsFormatted: [],
      fileTitle: "",
      csvHeaders: {
        barcode: "barcode",
        billDate: "b'illDate",
        department: "department",
        productId: "productId",
        productName: "productName",
        productDescription: "productDescription",
        quantity: "quantity",
        retailPrice: "retailPrice",
        realRetailPrice: "realRetailPrice",
        discount: "discount",
        subDepartment: "subDepartment",
      },
    };
  },
  created() {
    this.today = new Date();
    const timeStamp = new Date().getTime();
    const yesterdayTimeStamp = timeStamp - 24 * 60 * 60 * 1000;
    const beforeYesterdayTimeStamp = timeStamp - 24 * 60 * 60 * 1000 * 2;

    const yesterdayDate = new Date(yesterdayTimeStamp);
    this.yesterday = yesterdayDate;
    const beforeYesterdayDate = new Date(beforeYesterdayTimeStamp);
    this.beforeYesterday = beforeYesterdayDate;

    this.initialFinal = moment(this.today).format("MMMM Do YYYY");
    this.endFinal = moment(this.today).format("MMMM Do YYYY");

    this.today = moment(this.today).format("YYYY-M-D");
    this.yesterday = moment(this.yesterday).format("YYYY-M-D");
    this.beforeYesterday = moment(this.beforeYesterday).format("YYYY-M-D");

    this.editedDate.from = this.today.concat("T").concat("00:00:00");
    this.editedDate.to = this.today.concat("T").concat("23:59:59");
    //this.getDataFromApi();
  },
  watch: {
    page: function () {
      this.changePage();
    },
    searchProduct() {
      clearTimeout(this.searchProductTimeout);
      this.searchProductTimeout = setTimeout(() => {
        this.getProducts();
      }, 1500);
    },
  },
  filters: {
    // moment: function(date) {
    //   return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    // },
    localDate: function (date) {
      return moment.utc(date).local().format("MMM Do YYYY");
    },
  },

  methods: {
    getProducts() {
      if (this.searchProduct) {
        this.isProductLoading = true;
        this.$http
          .post(`/Menus/Products/Search`, {
            pageNumber: 1,
            pageSize: 10,
            search: this.searchProduct,
          })
          .then((res) => {
            this.products = res.data.items;
            this.isProductLoading = false;
          })
          .catch(() => {
            this.products = [];
            this.isProductLoading = false;
          });
      } else {
        this.products = [];
      }
    },
    currencyFormat(val) {
      return val ? "$" + parseFloat(val).toFixed(2) : "$0.00";
    },
    pdfDownload() {
      console.log(this.sales);
      const document = {
        pageOrientation: "landscape",
        content: [
          {
            text:
              "Products Sales Report   :  " +
              this.initialFinal +
              " to " +
              this.endFinal,
            fontStyle: 15,
            lineHeight: 2,
          },
          {
            text: "Total Income: $" + this.totalPrice,
            fontStyle: 15,
            lineHeight: 2,
          },

          {
            columns: [
              { text: "Bill Date", width: 150 },
              // { text: "Product Id", width: 50 },
              { text: "Department", width: 100 },
              { text: "Sub Department", width: 100 },
              { text: "Product", width: 100 },

              { text: "quantity", width: 50 },
              { text: "Real Retail Price", width: 100 },
              { text: "Retail Price", width: 100 },
              { text: "Discount", width: 50 },
            ],
            lineHeight: 2,
          },
        ],
      };
      this.sales.forEach((sales) => {
        document.content.push({
          columns: [
            {
              text: moment(sales.billDate).format("MMMM Do YYYY"),
              width: 150,
            },

            // { text: sales.productId, width: 50 },
            { text: sales.department, width: 100 },
            { text: sales.subdepartment, width: 100 },
            { text: sales.productName, width: 100 },

            { text: sales.quantity, width: 50 },
            { text: sales.realRetailPrice, width: 100 },
            { text: sales.retailPrice, width: 100 },
            { text: sales.discount, width: 50 },
          ],

          lineHeight: 2,
        });
      });
      pdfMake
        .createPdf(document)
        .download(
          "Products Sales Report_" + this.initialFinal + " to " + this.endFinal
        );
    },
    csvDownload() {
      this.fileTitle =
        "product_sales_" + this.initialFinal + "_to_" + this.endFinal;

      console.log(this.sales);
      this.sales.forEach((item) => {
        this.itemsFormatted.push({
          barcode: item.barcode,
          billDate: item.billDate,
          department: item.department,
          subDepartment: item.subDepartment,
          productId: item.productId,
          productName: item.productName,
          productDescription: item.productDescription,
          quantity: item.quantity,
          retailPrice: item.retailPrice,
          realRetailPrice: item.realRetailPrice,
          discount: item.discount,
        });
      });
      console.log(this.itemsFormatted);

      this.exportCSVFile(this.csvHeaders, this.itemsFormatted, this.fileTitle);
    },
    exportCSVFile(headers, items, fileTitle) {
      console.log("exportCSVFile");
      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle || "export.csv";

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      this.clearData();
    },
    convertToCSV(objArray) {
      console.log("convertToCSV");
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },

    clearData() {
      this.itemsFormatted = [];
      console.log("Cleared");
    },

    popupBarcode(item) {
      this.barcodeDialog = true;
      this.productBarcode = item.barcode;
    },

    getFirstDayOfYear(year) {
      return new Date(year, 0, 1);
    },
    getYearly() {
      const date = new Date();
      const firstDayYear = this.getFirstDayOfYear(date.getFullYear());
      this.monthFirst = moment(firstDayYear).format("YYYY-M-D");
      console.log(this.monthFirst);

      this.editedDate.from = this.monthFirst.concat("T").concat("00:00:00");
      this.editedDate.to = this.today.concat("T").concat("23:59:59");

      this.initialFinal = moment(this.monthFirst).format("MMMM Do YYYY");
      this.endFinal = moment(this.today).format("MMMM Do YYYY");

      console.log(this.editedDate);

      this.getData();
    },

    getFirstDayOfMonth(year, month) {
      return new Date(year, month, 1);
    },
    getMonthly() {
      const date = new Date();
      const firstDayCurrentMonth = this.getFirstDayOfMonth(
        date.getFullYear(),
        date.getMonth()
      );
      this.monthFirst = moment(firstDayCurrentMonth).format("YYYY-M-D");
      console.log(this.monthFirst);

      this.editedDate.from = this.monthFirst.concat("T").concat("00:00:00");
      this.editedDate.to = this.today.concat("T").concat("23:59:59");

      this.initialFinal = moment(this.monthFirst).format("MMMM Do YYYY");
      this.endFinal = moment(this.today).format("MMMM Do YYYY");

      console.log(this.editedDate);

      this.getData();
    },

    getToday() {
      this.specificDate = this.today;
      this.getSpecificDate();
    },

    getYesterday() {
      this.specificDate = this.yesterday;
      this.getSpecificDate();
    },
    getBeforeYesterday() {
      this.specificDate = this.beforeYesterday;
      this.getSpecificDate();
    },
    getSpecificDate() {
      this.editedDate.from = this.specificDate.concat("T").concat("00:00:00");
      this.editedDate.to = this.specificDate.concat("T").concat("23:59:59");

      this.initialFinal = moment(this.specificDate).format("MMMM Do YYYY");
      this.endFinal = moment(this.specificDate).format("MMMM Do YYYY");

      console.log(this.editedDate);

      this.getData();
    },
    getData() {
      if (this.product) {
        this.isLoading = true;
        // this.editedDate.from = this.initialFinal;
        //  this.editedDate.to = this.endFinal;
        console.log(this.editedDate);
        this.$http
          .post("/Sales/Product", {
            productId: this.product.productId,
            from: this.editedDate.from,
            to: this.editedDate.to,
          })
          .then((response) => {
            this.sales = response.data.data;
            //  this.sales = this.sales.reverse();
            this.totalPrice = 0;
            for (var i = 0; i < this.sales.length; i++) {
              this.totalPrice = this.totalPrice + this.sales[i].retailPrice;
            }

            this.isLoading = false;
          })
          .catch((error) => {
            console.log("Error");
            this.isLoading = false;
            if (error) {
              this.$toast.error("Error in getting Data", "Error", {
                position: "topRight",
              });
            }
          });
      } else {
        this.$toast.error("Product is not selected.", "Error", {
          position: "topRight",
        });
      }
    },
    getDataFromApi() {
      var local = new Date();
      var today = moment(local).format("YYYY-MM-DD");

      console.log(today);

      var utc = moment.utc(moment(local).utc()).format();
      console.log(utc);

      this.editedDate.from = today;
      this.editedDate.to = utc;
      console.log(this.editedDate);
      // var utcDate = moment.utc(moment(date).utc()).format();

      this.isLoading = true;
      this.$http
        .post("/Sales/Product", this.editedDate)
        .then((response) => {
          this.sales = response.data.data;
          // this.sales = this.sales.reverse();
          this.totalPrice = 0;
          for (var i = 0; i < this.sales.length; i++) {
            console.log(this.sales[i].retailPrice);
            this.totalPrice = this.totalPrice + this.sales[i].retailPrice;
          }

          this.isLoading = false;
        })
        .catch((error) => {
          console.log("Error");
          this.isLoading = false;
          if (error) {
            this.$toast.error("Error in getting Data", "Error", {
              position: "topRight",
            });
          }
        });
    },

    dateModalActive() {
      var local = new Date();
      this.date = moment(local).format("YYYY-MM-DD");

      if (this.setInitial == true) {
        this.time = "00:00:00";
      }
      if (this.setEnd == true) {
        this.time = "23:59:59";
        //this.time = moment(local).format("HH:mm");
      }

      this.dateModal = true;
    },
    closeDateModal() {
      if (this.setInitial == true) {
        this.editedDate.from = "";
        this.setInitial = false;
        this.dateModal = false;
      }
      if (this.setEnd == true) {
        this.editedDate.to = "";
        this.setEnd = false;
        this.dateModal = false;
      }
    },
    addDate() {
      if (this.setInitial == true) {
        var date = this.datePick.concat("T").concat(this.time);
        var utcDate = moment.utc(moment(date).utc()).format();
        this.editedDate.from = utcDate;

        console.log(this.editedDate.from);
        this.initialFinal = moment(this.editedDate.from).format("MMMM Do YYYY");
        this.setInitial = false;
        this.dateModal = false;
        this.minDate = "";
      }
      if (this.setEnd == true) {
        var date = this.datePick.concat("T").concat(this.time);
        var utcDate = moment.utc(moment(date).utc()).format();
        this.editedDate.to = utcDate;
        console.log(this.editedDate.to);
        this.endFinal = moment(this.editedDate.to).format("MMMM Do YYYY");
        this.setEnd = false;
        this.dateModal = false;
        this.maxDate = this.date;
      }

      //this.finalDate = "Hi";
      // this.finalDate =
      //   this.editedItem.initialDate | moment("dddd, MMMM Do YYYY, h:mm:ss a");
    },
    addPayment(item) {
      console.log(item);

      this.paymentView = true;
      this.billPayment.billNo = item.billNo;
      this.billPayment.billPaymentNo = item.billNo;

      this.billPayment.payAmount = item.billTotal;
    },
  },
};
</script>
<style>
.numberInput input[type="number"] {
  -moz-appearance: textfield;
}
.numberInput input::-webkit-outer-spin-button,
.numberInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.total-price {
  background-color: #30b72a;
  padding: 5px;
  text-transform: uppercase;
  border-radius: 5px;
  float: right;
}
</style>
